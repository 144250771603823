// TODO: tech debt - Move this to theme file
.MuiButton-root.Button {
    &.MuiButton {
        &-contained, &-outlined {
            border-radius: 22px;
            font-weight: 600;
            min-width: 280px;
            text-transform: none;
        }
    }

    &.Mui-disabled {
        cursor: not-allowed;
    }
    
    &.MuiButton {
        &-contained {
            background-color: var(--auto-purple);
            color: var(--white);

            &:hover {
                background-color: var(--auto-purple);
            }

            &.Mui-disabled {
                background: rgb(187, 189, 205);
            }
        }

        &-outlined {
            background-color: var(--white);
            border-color: var(--omnipod-grey);
            color: var(--omnipod-grey);

            &.Mui-disabled {
                border-color: var(--grey-30);
                color: var(--grey-50);
            }
        }
    }
}
