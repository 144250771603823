/**
 * @license
 * Generated by Zeplin
 * Copyright (C) 2021 - present Zeplin
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at

 *     http://www.apache.org/licenses/LICENSE-2.0

 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

:root {
    --auto-purple: #8250C3;
    --bright-purple: #9D77D0;
    --dark-purple: #602CA5;
    --omnipod-grey: #4d4d4d;
    --grey: #e6e6e6;
    --grey-30: #bababa;
    --grey-50: #8d8c8c;
    --grey-alt: #dcdcdc;
    --grey-dark: #6c757d;
    --temp-basal: #1ad1db;
    --app-link: #61dafb;
    --auto-limited: #546a7c;
    --low-bg: #f75e4c;
    --high-bg: #ffa700;
    --disabled: #bbbdcd;
    --manual-blue: #006fdd;
    --bright-blue: #007bff;
    --hypo-protect: #2bda7b;
    --black: #000000;
    --black-dimmed: rgb(0, 0, 0, 0.1);
    --light-grey: #f6f6f6;
    --light-grey2: #8e8e93;
    --white: #ffffff;
    --manual-graph: #7bacdd;
    --auto-graph: #9475d3;
    --dark-grey: #343434;
    --dark-grey2: #4D4D4D;
    --good-bg: #2cd97c;
    --up-color: #dff9eb;
    --down-color: #e5e5e5;
    --normal-color: #f8f8ff;
    --charcoal: #343a40;
    --bolus: #abc9e8;
    --basal: #d3ada3;
    --goal: #737373;
    --null: #8d8d93;
    --info: #D2F6F8;
    --warning: #FFEDCC;
    --light-purple: #F1ECF8;
    --success-green-100: #4FB209;
    --success-green-10: #DCFFEC;
}

$colorBorder: #a3a3a3;
$colorFg: #1b1a1a;
$colorFgAlt: #ffffff;
$colorFgAlt2: #6b6b6b;
$colorBg: #ffffff;
$colorBgShaded: #f6f6f6;
$colorSubTitle: #6e6e6e;
$colorPurple: #5b34b9;
$colorHigh: #ffa700;
$colorLow: #f75e4c;
$colorNormal: #f0f0f0;
$colorIconBg: #743dbc;
$colorIconFg: #ffffff;
$colorIconBorder: #743dbc;
$colorMessage: #000080;
$colorGood: #008000;
$colorBad: #c20202;
$colorPendingBg: #fafad2;
$colorPendingFg: #800000;
$colorNeutral: #ffffff;
$colorActive: #d3d3d3;
$colorHltBg: #ffff99;
$colorHltFg: #000080;